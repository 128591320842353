.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Shared
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button {
    border-radius: 100px;
}

/* Sections
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.section {
    padding: 8rem 0 7rem;
    text-align: center;
}
.section-heading,
.section-description {
    margin-bottom: 1.2rem;
}

/* Hero
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.phones {
    position: relative;
}
.phone {
    position: relative;
    max-width: 80%;
    margin: 3rem auto -12rem;
}
.phone + .phone {
    display: none;
}

/* Values
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.value-multiplier {
    margin-bottom: .5rem;
    color: #11DFC7;
}
.value-heading {
    margin-bottom: .3rem;
}
.value-description {
    opacity: .8;
    font-weight: 300;
}

/* Help
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.get-help {
    border-bottom: 1px solid #ddd;
}

/* Categories
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.categories {
    background-size: cover;
    color: #fff;
}
.categories .section-description {
    margin-bottom: 4rem;
}

/* Bigger than 550 */
@media (min-width: 550px) {
    .section {
        padding: 12rem 0 11rem;
    }
    .hero {
        padding-bottom: 12rem;
        text-align: left;
        height: 165px;
    }
    .phone {
        position: absolute;
        top: -7rem;
        right: 3rem;
        max-height: 362px;
        z-index: 3;
    }
    .phone + .phone {
        top: -6rem;
        display: block;
        max-width: 73.8%;
        right: 0;
        z-index: 2;
        max-height: 338px;
    }
    .hero-heading {
        font-size: 2.4rem;
    }
}

/* Bigger than 750 */
@media (min-width: 750px) {
    .hero {
        height: 190px;
    }
    .hero-heading {
        font-size: 2.6rem;
    }
    .section {
        padding: 14rem 0 15rem;
    }
    .hero {
        padding: 16rem 0 14rem;
    }
    .section-description {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .phone {
        top: -14rem;
        right: 5rem;
        max-height: 510px;
    }
    .phone + .phone {
        top: -12rem;
        max-height: 472px;
    }
    .categories {
        padding: 15rem 0 8rem;
    }
}


.coral-bg {
   background-color: coral!important;
}

.coral-color {
    color: coral!important;
}
.border-none {
    border: none  !important;
}

.icon-dimensions{
    box-sizing: border-box;
    height: 64px;
    width: 64px;
}

.pt-10{
    padding-top: 20px;
}

/* Bigger than 1000 */
@media (min-width: 1000px) {
    .section {
        padding: 20rem 0 19rem;
    }
    .hero {
        padding: 22rem 0;
    }
    .hero-heading {
        font-size: 3.0rem;
    }
    .phone {
        top: -16rem;
        max-height: 615px;
    }
    .phone + .phone {
        top: -14rem;
        max-height: 570px;
    }
}
